import { Link } from 'gatsby';
import * as React from 'react';

const NotHaveAccessPage = () => (
	<div className="centered">
		<h1 className="mb-2 text-4xl">403: Check access rules</h1>
		<p>You have no rules to access this content</p>
		<Link to="/">Back to Main</Link>
	</div>
);

export default NotHaveAccessPage;
